import {Layout} from "../../components/Layout"
import {Link, useTranslation} from "gatsby-plugin-react-i18next"
import og from "../../data/images/patreon/OpenGraph.png"
import {Page} from "../../components/PrivateRoutePage"
import {Button, Card, CardContent, Grid} from "@material-ui/core"
import {ResponsiveContainer, SectionContainer, StarsImageContainer} from "../../components/Shared"
import {TransMdTypo} from "../../components/i18n/TransMdTypo"
import {PatreonTitleGrid} from "./Shared"
import {PatreonPage} from "./PatreonPage"
import {useBreadcrumb} from "../../hooks/useBreadcrumb"
import {TextCenter} from "../diy/components/Shared";

const NotAPatron = () => {
  const {t} = useTranslation()
  const breadCrumb = useBreadcrumb(NotAPatronPage)

  return <Layout title={t("patreon^Recalbox + Patreon")} imageUrl={og}>
    <StarsImageContainer>
      <SectionContainer>
        {breadCrumb}
      </SectionContainer>
      <ResponsiveContainer>
        <Grid container justify={"center"} spacing={3}>
          <PatreonTitleGrid/>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <TransMdTypo gutterBottom variant={"h4"}>patreon^Almost a Patron of Recalbox</TransMdTypo>
                <TransMdTypo children={"patreon^Patreon link created"}/>
                <TextCenter>
                <Button to={PatreonPage.getUrl()} component={Link} css={{marginTop: "2em"}} variant={"outlined"}
                        color={"secondary"}>{t("patreon^Support us on Patreon")}</Button>
                </TextCenter>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </ResponsiveContainer>
    </StarsImageContainer>
  </Layout>
}


const getUrl =  () => "/patreon/notapatron/"
export const NotAPatronPage: Page = {
  Page: NotAPatron,
  getUrl,
  parentPage: () => PatreonPage,
  breadCrumb: {link: getUrl(), name: "Patron Soon"}
}
