import {Grid} from "@material-ui/core"
import {ResponsiveContainer, SectionContainer, StarsImageContainer} from "../../../components/Shared"
import {usePatreon} from "./usePatreon"
import React from "react"
import {useTranslation} from "gatsby-plugin-react-i18next"
import {PatronNav} from "./PatronNav";
import {Layout} from "../../../components/Layout";
import {useBreadcrumb} from "../../../hooks/useBreadcrumb";
import {ProfilePage} from "./ProfilePage";
import {Page} from "../../../components/PrivateRoutePage";
import {SteppedLineTo} from 'react-lineto';
import {CgUiKit} from "@react-icons/all-files/cg/CgUiKit";
import {BsClock} from "@react-icons/all-files/bs/BsClock";
import {AiFillHeart} from "@react-icons/all-files/ai/AiFillHeart";
import {BsDownload} from "@react-icons/all-files/bs/BsDownload";
import {BsImage} from "@react-icons/all-files/bs/BsImage";
import {GiMeshNetwork} from "@react-icons/all-files/gi/GiMeshNetwork";

type RoadmapType = {
  id: string,
  name: string,
  description: string,
  points: number,
  icon: React.ReactNode,
  link: string,
  type: "UI" | "Marketplace" | "System"
  children?: RoadmapType[]
}
const RoadmapJson : RoadmapType[] = [
  {
    id:"newui",
    name: "New UI",
    description: "Une toute nouvelle interface utilisateur !",
    points: 100,
    icon: <CgUiKit/>,
    link: "https://www.figma.com",
    type: "UI",
    children: [
      {
        id:"view-like",
        name: "Vue: Vous aimerez aussi!",
        description: "Une selection de jeu en fonction des jeux auxquels vous avez joué, pour decouvrir des perles sur votre Recalbox !",
        points: 10,
        icon: <AiFillHeart/>,
        link: "https://www.figma.com",
        type: "UI",
      },
      {
        id:"view-last",
        name: "Vue: Vos derniers jeux joués!",
        description: "Retrouvez en uin clin d'oeil les jeux auxquels vous avez joué!",
        points: 3,
        icon: <BsClock/>,
        link: "https://www.figma.com",
        type: "UI",
      },
      {
        id:"marketplace",
        name: "Marketplace JEUX",
        description: "Téléchargez et jouez a des jeux et démos directement depuis votre Recalbox !",
        points: 8,
        icon: <BsDownload/>,
        link: "https://www.figma.com",
        type: "Marketplace",
        children: [
          {
            id:"marketplace-themes",
            name: "Marketplace Thems",
            description: "Visualisez en live et téléchargez en un click les themes pour votre Recalbox!",
            points: 6,
            icon: <BsImage/>,
            link: "https://www.figma.com",
            type: "Marketplace",
          }
        ]
      }
    ]
  },
  {
    id:"nas",
    name: "Support natif NAS",
    description: "Support natif des nas dans les options du frontend",
    points: 20,
    icon: <GiMeshNetwork/>,
    link: "https://www.figma.com",
    type: "System",
  }
]

const RoadmapElement = (props: { roadmapElement: RoadmapType, className?: string }) => {
  return <div className={props.className}
              css={{height: "100px", width: "100px", border: "solid 3px", borderRadius: "50%"}}>
    {props.roadmapElement.name}
  </div>
}

const getRoadmapGrids = (element: RoadmapType) => {

  let groupedFeatures = [[element]]
  let level = 0
  while (groupedFeatures[level].length > 0) {
    groupedFeatures[++level] = []
    groupedFeatures[level-1].map(element => groupedFeatures[level] = groupedFeatures[level].concat(element.children || []))
  }

  return <React.Fragment>
      {groupedFeatures.map(group => {
        return <Grid item>
          {group.map(element => (
            <RoadmapElement className={"a"} roadmapElement={element}/>
          ))
          }
        </Grid>
      })}

  </React.Fragment>
  //                  <SteppedLineTo from="a" to="c" fromAnchor={"right"} toAnchor={"left"} orientation={"h"}/>
}
export const RoadmapComponent = () => {
  const patreon = usePatreon()
  const {t} = useTranslation()
  const breadCrumb = useBreadcrumb(RoadmapPage)

  return <Layout title={t("patreon^Roadmap")}>
    <StarsImageContainer>
      <SectionContainer>
        {breadCrumb}
      </SectionContainer>
      <ResponsiveContainer>
        <PatronNav tab={"roadmap"}/>
        <Grid container spacing={2}>
          {RoadmapJson.map((firstFeature) => {
            return <Grid item xs={12}>
              <Grid container spacing={10} alignItems={"center"}>
                {getRoadmapGrids(firstFeature)}
              </Grid>
            </Grid>
          })}

        </Grid>
      </ResponsiveContainer>
    </StarsImageContainer>
  </Layout>
}

const getUrl = () => `/patreon/patron/roadmap/`
export const RoadmapPage: Page = {
  Page: RoadmapComponent,
  getUrl,
  parentPage: () => ProfilePage,
  breadCrumb: {link: getUrl(), name: "Roadmap"}
}
