import {Page} from "../../../components/PrivateRoutePage"
import {Redirect, Router, useLocation} from "@reach/router"
import {ProfilePage} from "./ProfilePage"
import {useI18next} from "gatsby-plugin-react-i18next"
import * as queryString from "query-string"
import {useDispatch} from "react-redux"
import {notification} from "../../notification/notificationSlice"
import {PatreonPrivateRoute, PatreonRoute} from "../Routes"
import {Layout} from "../../../components/Layout"
import {useI18nLink} from "../../../components/i18n/I18nLink"
import * as React from "react"
import {useEffect} from "react"
import {PatreonPage} from "../PatreonPage"
import {usePatreon} from "./usePatreon"
import {navigate} from "gatsby"
import {DownloadPatronVersionPage} from "./DownloadPatronVersionPage"
import {GoodiesPage} from "./GoodiesPage";
import {WallpaperPage} from "./goodies/WallpaperPage";
import {MixtapePage} from "./goodies/MixtapePage";
import {BadgesPage} from "./BadgesPage";
import {RoadmapPage} from "./RoadmapPage";
import {RecalboxRGBDualOrderPage} from "../../rgbdual/order/RecalboxRGBDualOrder";
const debug = process.env.GATSBY_DEBUG

const RedirectFromPatreonPage = () => {
  const patreon = usePatreon()
  const location = useLocation()
  const dispatch = useDispatch()
  const i18nLink = useI18nLink()

  useEffect(() => {
    const parsed = queryString.parse(location.search)
    const code = parsed.code
    const state = parsed.state
    if (!patreon.checkState(state as string)) {
      dispatch(notification("An error occured (0x12)", "warning"))
      navigate(i18nLink.getLink(PatreonPage.getUrl()))
    } else {
      if (typeof code === "string") {
        patreon.createToken(code).then((token) => {
          if (token.status === 200) {
            token.json().then((tokenJson) => {
              patreon.setToken(tokenJson)
              navigate(i18nLink.getLink(ProfilePage.getUrl(true)))
            })
          } else {
            dispatch(notification("Unable to connect!", "warning"))
            navigate(i18nLink.getLink(PatreonPage.getUrl()))
          }
        }).catch((e) => {
          dispatch(notification("Unable to connect!", "warning"))
          navigate(i18nLink.getLink(PatreonPage.getUrl()))
        })
      }
    }

  }, [])

  if (typeof window === "undefined") {
    return <Redirect to={"/patreon/"}/>
  }

  return <Layout title={"Patreon"}></Layout>
}


const PatreonArea = () => {
  const i18next = useI18next()
  const prefix = i18next.defaultLanguage == i18next.language ? "" : `${i18next.language}/`
  return <Router basepath={`/${prefix}patreon/patron`}>
    <PatreonPrivateRoute component={ProfilePage.Page} path="/profile/"/>
    <PatreonPrivateRoute component={WallpaperPage.Page} path="/goodies/wp/:uid"/>
    <PatreonPrivateRoute component={MixtapePage.Page} path="/goodies/mixtape/:uid"/>
    <PatreonPrivateRoute component={GoodiesPage.Page} path="/goodies/"/>
    <PatreonPrivateRoute component={BadgesPage.Page} path="/badges/"/>
    <PatreonPrivateRoute component={RoadmapPage.Page} path="/roadmap/"/>
    <PatreonPrivateRoute component={DownloadPatronVersionPage.Page} path="/download/"/>
    <PatreonPrivateRoute component={RecalboxRGBDualOrderPage.Page} path="/recalbox-rgb-dual-order/"/>
    <PatreonRoute component={RedirectFromPatreonPage} path="/logged"/>
    <PatreonPrivateRoute component={ProfilePage.Page} path="/"/>
  </Router>

}

export const PatreonAreaPage: Page = {Page: PatreonArea, getUrl: () => "/patreon/patron/"}
