import React, {Component, useEffect, useState} from "react"
import {navigate, RouteComponentProps} from "@reach/router"
import {usePatreon} from "./patron/usePatreon"
import {navigate as gatsbyNav} from "gatsby"
import {PatreonPage} from "./PatreonPage"
import {useDispatch} from "react-redux"
import {notification} from "../notification/notificationSlice"
import {useTranslation} from "gatsby-plugin-react-i18next"
import {useI18nLink} from "../../components/i18n/I18nLink"
import {NotAPatronPage} from "./NotAPatronPage"
import {Layout} from "../../components/Layout";

export type PrivateRouteComponent = React.ComponentType<RouteComponentProps>

export interface PrivateRouteProps extends RouteComponentProps {
  component: PrivateRouteComponent
}

export const PatreonPrivateRoute: React.FC<PrivateRouteProps> =
  ({
     component: Component
     , ...rest
   }) => {
    const [access, setAccess] = useState(false)
    const patreon = usePatreon()
    const dispatch = useDispatch()
    const {t} = useTranslation()
    const i18nLink = useI18nLink()
    useEffect(() => {
      if (!patreon.currentToken()) {
        const state = `${Math.random()}`
        patreon.setState(state)
        navigate(patreon.loginUrl(state))
      } else {
        patreon.migrateToken()
        patreon.getUserInfo().then(userInfo => {
          if (userInfo.patron_status === "active_patron") {
            setAccess(true)
          } else {
            dispatch(notification(t("patreon^Not a patron anymore"), "warning"))
            patreon.clearAll()
            gatsbyNav(i18nLink.getLink(PatreonPage.getUrl()))
          }
        }).catch(e => {
          dispatch(notification(t("patreon^Almost a Patron"), "success"))
          patreon.clearAll()
          gatsbyNav(i18nLink.getLink(NotAPatronPage.getUrl()))
        })
      }
    }, [])
    if (!patreon.currentToken()) {
      return <div>Redirecting</div>
    } else {
      return access ? <Component {...rest} /> : <Layout title={"Loading"}/>
    }
  }

export const PatreonRoute: React.FC<PrivateRouteProps> =
  ({
     component: Component
     , ...rest
   }) => {
    return <Component {...rest} />
  }


